import React from "react"
import { Link } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";

import * as styles from "./LatestArticle.module.scss"

const LatestArticle = ({ title, excerpt, featuredImage, slug }) => (
  <Link to={slug} className={styles.link}>
    <div className={styles.LatestArticle}>
      {featuredImage
        ? (
          <GatsbyImage
          image={featuredImage?.childImageSharp?.gatsbyImageData}
          alt={title}
          className={styles.image}
        />
        ) : (
          <StaticImage
            src="../../../../../../static/assets/featuredImage.png"
            alt="CBS logo placeholder"
            className={styles.image}
          />
        )
      }
      
      <div className={styles.rightPart}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.date}>{excerpt}</p>
      </div>
    </div>
  </Link>
)

export default LatestArticle
