import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import * as styles from "./customButton.module.scss"

const CustomButton = ({
  externalLink,
  link,
  text,
  darkGreen,
  lightGreen,
  lightGreenMobile,
  whiteBorder,
  whiteBgHover,
  whiteBg,
  isBuySell,
  fullWidth,
  children,
  customStyle,
}) => (
  !externalLink
  ?
  <Link
    to={link}
    className={classNames(
      styles.CustomButton,
      darkGreen && styles.darkGreen,
      lightGreen && styles.lightGreen,
      lightGreenMobile && styles.lightGreenMobile,
      whiteBorder && styles.whiteBorder,
      whiteBgHover && styles.whiteBgHover,
      whiteBg && styles.whiteBg,
      isBuySell && styles.buySellBtn,
      fullWidth && styles.fullWidth,
      customStyle
    )}
  >
    {!!text ? text : children}
  </Link>
  :
  <a href={link}
    className={classNames(
      styles.CustomButton,
      darkGreen && styles.darkGreen,
      lightGreen && styles.lightGreen,
      lightGreenMobile && styles.lightGreenMobile,
      whiteBorder && styles.whiteBorder,
      whiteBgHover && styles.whiteBgHover,
      whiteBg && styles.whiteBg,
      isBuySell && styles.buySellBtn,
      fullWidth && styles.fullWidth,
      customStyle
  )}>
    {!!text ? text : children}
  </a>
)

export default CustomButton
