import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import * as styles from "./SidebarCard.module.scss"
import classNames from "classnames"
import CustomButton from "../../../../CustomButton"

const SidebarCard = ({
  title,
  text,
  checkboxes,
  remark,
  buttonLink,
  buttonText,
  footer,
  pageType,
  externalLink,
}) => (
  <div
    className={classNames(
      styles.SidebarCard,
      pageType[0] === "buy" && styles.BuyPage
    )}
  >
    {pageType === "moreInformationSubPage" ? (
      <h2
        className={styles.titleMoreInformationSubPage}
        dangerouslySetInnerHTML={{ __html: title }}
      />
    ) : (
      <h2
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: title }}
      />
    )}

    <div className={styles.underLine} />
    {!!checkboxes && (
      <div className={styles.checkboxes}>
        {checkboxes.map((item, index) => (
          <div className={styles.checkboxLine} key={index}>
            <div className={styles.icon}>
              <GatsbyImage
                image={item?.icon?.childImageSharp?.gatsbyImageData}
                className={styles.image}
                key={index}
                alt="List item icon"
              />
            </div>
            <span>{item.text}</span>
          </div>
        ))}
      </div>
    )}

    <p className={styles.text}>{text}</p>

    {!!remark && <span className={styles.remark}>{remark}</span>}

    {!!buttonLink && (
      <div className={styles.button}>
        <CustomButton link={buttonLink} text={buttonText} whiteBg={true} customStyle={styles.customButton} externalLink={!!externalLink} />
      </div>
    )}

    {!!footer && <span className={styles.footer}>{footer}</span>}
  </div>
)

export default SidebarCard
