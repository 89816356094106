// extracted by mini-css-extract-plugin
export var ContentBottom = "ContentBottom-module--ContentBottom--9-MjA";
export var wrapper = "ContentBottom-module--wrapper--ogHE-";
export var darkGrayTrapezoid = "ContentBottom-module--darkGrayTrapezoid--2phmn";
export var darkGreenTrapezoid = "ContentBottom-module--darkGreenTrapezoid--11tZC";
export var content = "ContentBottom-module--content--7Yr11";
export var inner = "ContentBottom-module--inner--ktvq4";
export var smallTitle = "ContentBottom-module--smallTitle--2RqMN";
export var title = "ContentBottom-module--title--zCxF1";
export var underLine = "ContentBottom-module--underLine--29b6Z";
export var text = "ContentBottom-module--text--3gFQQ";
export var image = "ContentBottom-module--image--1IBka";