import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import * as styles from "./ContentBottom.module.scss"
import CustomButton from "../../../CustomButton/index"

const ContentBottom = ({ contentBottomData, pageType }) => (
  <div className={styles.ContentBottom}>
    <div className={styles.wrapper}>
      <div className={styles.darkGrayTrapezoid}>
        <div className={styles.darkGreenTrapezoid}>
          <div className={styles.content}>
            <div className={styles.inner}>
              <p className={styles.smallTitle}>Snabbt, enkelt och tryggt!</p>
              <h2 className={styles.title}>
                {pageType === "sell"
                  ? contentBottomData.sellPage.title
                  : contentBottomData.buyPage.title}
              </h2>
              <div className={styles.underLine} />
              <p className={styles.text}>
                {pageType === "sell"
                  ? contentBottomData.sellPage.text
                  : contentBottomData.buyPage.text}
              </p>
              <div>
                <CustomButton
                  text={
                    pageType === "sell"
                      ? contentBottomData.sellPage.buttonText
                      : contentBottomData.buyPage.buttonText
                  }
                  link={
                    pageType === "sell"
                      ? contentBottomData.sellPage.link
                      : contentBottomData.buyPage.link
                  }
                  lightGreen={true}
                  externalLink
                />
              </div>
            </div>
            <div className={styles.image}>
              <StaticImage
                src="../../../../../static/assets/linda-i-stefan.png"
                alt="man and women"
                placeholder="blurred"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)


export default ContentBottom
