import React from "react"
import CustomButton from "../../CustomButton"
import * as styles from "./readyToLiquidate.module.scss"

const ReadyToLiquidate = ({
                            readyToLiquidateTitle,
                            readyToLiquidateSubtitle,
                            readyToLiquidateImages,
                            readyToLiquidateBottomTitle,
                            readyToLiquidateBottomSubtitle,
                            readyToLiquidateButtonText,
                            readyToLiquidateButtonUrl,
                            readyToLiquidateTextBelowButton,
                            isBuyPage,
                            isPreview
  }) => {

  return (
    <div className={styles.readyContainer}>
      <div className={styles.topBg}>
        <div className={styles.topContainer}>
          <div className={styles.title}>{readyToLiquidateTitle}</div>
          <div className={styles.subtitle}>{readyToLiquidateSubtitle}</div>
          <div className={styles.borderLine} />
        </div>
        <div className={styles.scroll}>
          {readyToLiquidateImages && readyToLiquidateImages.map((img, index) =>
            (<div className={styles.scrollItem} key={index}>
              <img
                src={isPreview ? img?.employee : img?.employee?.childImageSharp?.fluid?.src}
                alt={img}
                className={styles.circleImage}
              />
            </div>)
          )}
        </div>
        <div className={styles.greenTriangle}/>
      </div>
      <div className={styles.bottomBg}>
        <div className={styles.bottomContainer}>
          <div className={styles.bottomTitle}>
            {readyToLiquidateBottomTitle}
          </div>
          <div className={styles.bottomSubtitle}>
            {readyToLiquidateBottomSubtitle}
          </div>
          <CustomButton
            text={readyToLiquidateButtonText}
            isBuySell= {true}
            link={`${process.env.GATSBY_HOST_URL}${readyToLiquidateButtonUrl}`}
            whiteBorder={true}
            fullWidth = {true}
            externalLink
          />
          {isBuyPage && (
            <div className={styles.buyPageSlogan}>{readyToLiquidateTextBelowButton}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReadyToLiquidate
