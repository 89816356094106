import React from "react"
import CustomButton from "../../../../CustomButton"
import * as styles from "./BottomCard.module.scss"

const BottomCard = ({ title, text, buttonLink, contactInfo }) => (
  <div className={styles.BottomCard}>
    <h3 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
    <p className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />

    {contactInfo && (
      <p
        className={styles.contactInfo}
        dangerouslySetInnerHTML={{ __html: contactInfo }}
      />
    )}

    {buttonLink && (
      <div className={styles.button}>
        <CustomButton link={buttonLink} text="Läs mer" darkGreen />
      </div>
    )}
  </div>
)

export default BottomCard
