import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "cbs-layout-wrapper/dist/style.css"
import * as LayoutWrapper from "cbs-layout-wrapper"
import Sidebar from "./components/Sidebar"
import PageBottom from "./components/PageBottom"
import HeroBanner from "./components/HeroBanner"
import ContentBottom from "./components/ContentBottom"
import ReadyToLiquidate from "../SellBuyPage/ReadyToLiquidate/index"

import "../../styles/reset.css"
import * as styles from "./Layout.module.scss"

const Layout = ({
  children,
  className,
  sidebar,
  slogan,
  showTopContent,
  showBottomContent,
  pageType,
  heroData,
  contentBottomData,
  bgImage,
  hideBottomPage,
  moreInformationSubPage,
  readyToLiquidateMock,
}) => (
  <div className={styles.Layout}>
    <div className={styles.headerWrapper}>
      <LayoutWrapper.TopBar slogan={slogan} LinkComponent={SharedLink} />

      <LayoutWrapper.Header linkComponent={SharedLink} />
    </div>

    {sidebar && showTopContent && (
      <HeroBanner
        heroData={heroData}
        pageType={pageType}
        backgroundImage={bgImage}
      />
    )}

    <div className={styles.mainContainer}>
      <main className={className}>{children}</main>

      {sidebar && (
        <aside className={styles.sidebar}>
          <Sidebar
            sidebar={sidebar}
            pageType={pageType}
            sidebarCardData={moreInformationSubPage?.sidebarCard}
          />
        </aside>
      )}
    </div>

    {pageType === "moreInformationSubPage" && (
      <ReadyToLiquidate
        readyToLiquidateMock={readyToLiquidateMock}
        isBuyPage={moreInformationSubPage?.heroBanner[0]?.pageType === "buy"}
      />
    )}

    {sidebar && showBottomContent && pageType !== "moreInformationSubPage" && (
      <ContentBottom
        pageType={pageType}
        contentBottomData={contentBottomData}
      />
    )}

    {!hideBottomPage && <PageBottom />}

    <LayoutWrapper.Banner />

    <LayoutWrapper.Footer LinkComponent={SharedLink} />

  </div>
)

Layout.defaultProps = {
  hideBottomPage: false,
}

Layout.propTypes = {
  hideBottomPage: PropTypes.bool,
}

const absoluteLinks = ["/avveckla-aktiebolag/pris", "/kopa-lagerbolag/pris"]
export function SharedLink(props) {
  return absoluteLinks.includes(props.to)
    ? React.createElement("a", {
        ...props,
        href: props.to,
      })
    : React.createElement(Link, props)
}

export default Layout
