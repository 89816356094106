import React from "react"

import BottomCard from "./BottomCard"

import * as styles from "./PageBottom.module.scss"

const PageBottom = () => {
  const cards = [
    {
      title: "<strong>AVVECKLA</strong> AKTIEBOLAG",
      text:
        "Sälj ditt bolag till oss för snabbavveckling till marknadsledande villkor!",
      buttonLink: "/avveckla-aktiebolag",
      contactInfo: "",
    },
    {
      title: "KONTAKTA OSS",
      text: "Citadellet Bolagsservice AB <br/> Strandgatan 7, 185 32 Vaxholm\n",
      buttonLink: null,
      contactInfo:
        "Tel: 08-23 01 23\n &nbsp; <a href='mailto:info@cbs.se'>info@cbs.se</a>",
    },
    {
      title: "KÖPA <strong>LAGERBOLAG</strong>",
      text:
        "Starta bolag med våra lagerbolag på 2 tim till marknadens bästa erbjudande!",
      buttonLink: "/kopa-lagerbolag",
      contactInfo: "",
    },
  ]

  return (
    <div className={styles.PageBottom}>
      <div className={styles.container}>
        <div className={styles.innerWrapper}>
          {cards.map((item, index) => (
            <BottomCard
              title={item.title}
              text={item.text}
              buttonLink={item.buttonLink}
              contactInfo={item.contactInfo}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default PageBottom
