// extracted by mini-css-extract-plugin
export var readyContainer = "readyToLiquidate-module--readyContainer--mz77e";
export var topBg = "readyToLiquidate-module--topBg--3j1TA";
export var topContainer = "readyToLiquidate-module--topContainer--2OUkt";
export var title = "readyToLiquidate-module--title--Otlmt";
export var subtitle = "readyToLiquidate-module--subtitle--ophjw";
export var borderLine = "readyToLiquidate-module--borderLine--CN6YM";
export var bottomBg = "readyToLiquidate-module--bottomBg--2cNYa";
export var bottomContainer = "readyToLiquidate-module--bottomContainer--23NW3";
export var bottomTitle = "readyToLiquidate-module--bottomTitle--3QB4K";
export var bottomSubtitle = "readyToLiquidate-module--bottomSubtitle--18fp8";
export var buyPageSlogan = "readyToLiquidate-module--buyPageSlogan--3SBJ3";
export var greenTriangle = "readyToLiquidate-module--greenTriangle--2VMvu";
export var scroll = "readyToLiquidate-module--scroll--3VvP-";
export var scrollItem = "readyToLiquidate-module--scrollItem--3s7WP";
export var circleImage = "readyToLiquidate-module--circleImage--jhQha";