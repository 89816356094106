import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import SidebarCard from "./SidebarCard"
import BlockQuote from "../../../BlockQuote"

import * as styles from "./Sidebar.module.scss"

import LatestArticle from "./LatestArticle"

const Sidebar = ({ pageType, sidebar, sidebarCardData }) => {
  const sidebarData = useStaticQuery(graphql`
    {
      quotes: allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "quoteCard" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              quoteCard {
                author
                text
                position
              }
            }
          }
        }
      }
      cards: allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "sidebarCard" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              sidebarCard {
                body
                pageType
                checkboxes {
                  text
                  icon {
                    childImageSharp {
                      gatsbyImageData(width: 50, layout: CONSTRAINED)
                    }
                  }
                }
                footer
                linkPath
                linkTitle
                remark
                title
              }
            }
          }
        }
      }
      latestArticles: allMarkdownRemark(
        sort: { fields: frontmatter___date, order: DESC }
        filter: {
          frontmatter: { date: { ne: "null" }, template: { eq: "blog-post" } }
        }
        limit: 5
      ) {
        nodes {
          excerpt(pruneLength: 45)
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            slug
            featuredImage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `)

  const quotes = sidebarData.quotes.edges[0].node.frontmatter.quoteCard
  const cards = sidebarData.cards.edges[0].node.frontmatter.sidebarCard
  const latestArticles = sidebarData.latestArticles.nodes

  return (
    <>
      <a
        href="https://www.uc.se/risksigill2?showorg=5568279367&language=swe"
        target="_blank"
        rel="noreferrer"
      >
        <StaticImage
          className={styles.logo}
          src="../../../../../static/assets/uc-sigill-txt-black.png"
          alt="sidebarLogo"
          placeholder="blurred"
        />
      </a>
      {pageType === "moreInformationSubPage" &&
        sidebarCardData.map((item, index) => {
          return (
            <SidebarCard
              title={item.title}
              text={item.body}
              buttonText={item.linkTitle}
              buttonLink={item.linkPath}
              checkboxes={item.checkboxes}
              remark={item.remark}
              footer={item.footer}
              pageType={pageType}
              key={index}
              externalLink
            />
          )
        })}
      {cards &&
        cards.map((item, index) => {
          const show = item.pageType.filter(type => type === pageType)
          if (show.length > 0) {
            return (
              <SidebarCard
                title={item.title}
                text={item.body}
                buttonText={item.linkTitle}
                buttonLink={item.linkPath}
                checkboxes={item.checkboxes}
                remark={item.remark}
                footer={item.footer}
                pageType={item.pageType}
                key={index}
                externalLink
              />
            )
          }
          return false
        })}
      {sidebar === "all-articles" ? (
        <div>
          {quotes &&
            quotes.map((item, index) => (
              <BlockQuote
                text={item.text}
                author={item.author}
                position={item.position}
                key={index}
              />
            ))}
          <h1 className={styles.latestArticles}>Senaste Artiklar</h1>
          {latestArticles &&
            latestArticles.map((item, index) => (
              <LatestArticle
                title={item.frontmatter.title}
                excerpt={item.excerpt}
                slug={item.frontmatter.slug}
                featuredImage={item.frontmatter.featuredImage}
                key={index}
              />
            ))}
        </div>
      ) : (
        <div>
          <h1 className={styles.latestArticles}>Senaste Artiklar</h1>
          {latestArticles &&
            latestArticles.map(
              (item, index) =>
                index < 3 && (
                  <LatestArticle
                    title={item.frontmatter.title}
                    excerpt={item.excerpt}
                    slug={item.frontmatter.slug}
                    featuredImage={item.frontmatter.featuredImage}
                    key={index}
                  />
                )
            )}
          {quotes &&
            quotes.map((item, index) => (
              <BlockQuote
                text={item.text}
                author={item.author}
                position={item.position}
                key={index}
              />
            ))}
        </div>
      )}
    </>
  )
}

export default Sidebar
