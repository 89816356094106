import React from "react"

import * as styles from "./HeroBanner.module.scss"
import CustomButton from "../../../CustomButton/index"

const HeroBanner = ({ heroData, pageType, backgroundImage }) => (
  <div className={styles.HeroBanner}>
    <img src={backgroundImage} className={styles.image} alt="hero-banner" />
    <div>
      <div className={styles.shadow} />
    </div>
    <div className={styles.content}>
      {pageType === 'moreInformationSubPage' &&
        <h1 className={styles.title}>{heroData[0].title}</h1>
      }
      {['sell', 'buy', 'other'].includes(pageType) &&
        <h2 className={styles.title}>
          {pageType === "sell" && heroData.sellPage.title}
          {pageType === "buy" && heroData.buyPage.title}
          {pageType === "other" && heroData.title}
        </h2>
      }
      {(pageType === "sell" || pageType === "buy") && (
        <CustomButton
          text={
            pageType === "sell"
              ? heroData.sellPage.buttonText
              : heroData.buyPage.buttonText
          }
          link={
            pageType === "sell" ? heroData.sellPage.link : heroData.buyPage.link
          }
          whiteBorder={true}
          customStyle={styles.customButtonStyle}
        />
      )}
      {pageType === "moreInformationSubPage" && (
        <CustomButton
          text={heroData[0].linkTitle}
          link={heroData[0].linkPath}
          whiteBorder={true}
          customStyle={styles.customButtonStyle}
        />
      )}

      {pageType === "other" && (
        <p className={styles.description}>{heroData.description}</p>
      )}
    </div>
  </div>
)

export default HeroBanner
