import React from "react"

import * as styles from "./BlockQuote.module.scss"

const BlockQuote = ({ text, author, position }) => (
  <blockquote className={styles.BlockQuote}>
    <p className={styles.text}>{text}</p>
    <div className={styles.underLine}></div>
    <cite>{author}</cite>
    <p className={styles.position}>{position}</p>
  </blockquote>
)

export default BlockQuote
