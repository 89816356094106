// extracted by mini-css-extract-plugin
export var SidebarCard = "SidebarCard-module--SidebarCard--2TvpV";
export var BuyPage = "SidebarCard-module--BuyPage--1yKTi";
export var titleMoreInformationSubPage = "SidebarCard-module--titleMoreInformationSubPage--3kRxV";
export var title = "SidebarCard-module--title--4C7TS";
export var underLine = "SidebarCard-module--underLine--1C14r";
export var checkboxes = "SidebarCard-module--checkboxes--2iZmj";
export var checkboxLine = "SidebarCard-module--checkboxLine--16FvZ";
export var icon = "SidebarCard-module--icon--l4CCm";
export var image = "SidebarCard-module--image--1dnja";
export var text = "SidebarCard-module--text--3LvKs";
export var remark = "SidebarCard-module--remark--1xppT";
export var button = "SidebarCard-module--button--2jSrF";
export var customButton = "SidebarCard-module--customButton--1Dkg4";
export var footer = "SidebarCard-module--footer--HQkG3";